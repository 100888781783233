import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { ComponentProps } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import config from "../../utils/config";

export { default as WithAuthenticationRequired } from "./WithAuthenticationRequired";

type AuthProviderProps = Partial<
  Omit<ComponentProps<typeof Auth0Provider>, "onRedirectCallback">
>;

export const AuthProvider = (props: AuthProviderProps) => {
  // read the config here because sometimes config may not be available at the time of import
  const [searchParams] = useSearchParams();
  const auth0ProviderOptions: Auth0ProviderOptions = {
    domain: config.AUTH0_DOMAIN,
    clientId: config.AUTH0_CLIENT_ID,
    authorizationParams: {
      audience: config.AUTH0_AUDIENCE,
      redirect_uri: window.location.origin,
    },
    cacheLocation: "localstorage",
    useRefreshTokens: false,
  };

  const location = useLocation();
  const navigate = useNavigate();

  const onRedirectCallback = (appState: { returnTo?: string } = {}) => {
    navigate(appState.returnTo || location.pathname, { replace: true });
  };

  const error = searchParams.get("error");
  if (error) {
    const title =
      {
        access_denied: "Access Denied",
      }[error] || "Error";
    const description = searchParams.get("error_description") || "";
    return (
      <div
        style={{
          overflow: "hidden",
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    );
  }

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      {...auth0ProviderOptions}
      {...props}
    />
  );
};
