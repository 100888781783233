import { ComponentType, PropsWithChildren } from "react";
import { useRoutes } from "react-router";

import { AuthProvider } from "../auth";
import ConfigChecker from "../config/ConfigChecker";
import { ErrorBoundary } from "../errors";
import { routes } from ".";

// Routes usually has JSX children but because we already have the object form
// we have to create our own Routes component with statically imported routes.
function StaticRoutes() {
  return useRoutes(routes);
}

// This should never render as all throwing components are rendered within a more meaningful error boundary.
// Minimal effort has gone into displaying this error. It's here "just in case" for debugging purposes.
const renderAppFallback = (props: { error: Error }) => (
  <code>{Error.prototype.toString.call(props.error)}</code>
);

type AppProps = { router: ComponentType<PropsWithChildren> };

export default function App({ router: Router }: AppProps) {
  return (
    <ErrorBoundary fallback={renderAppFallback}>
      <Router>
        <ConfigChecker>
          <AuthProvider>
            <StaticRoutes />
          </AuthProvider>
        </ConfigChecker>
      </Router>
    </ErrorBoundary>
  );
}
