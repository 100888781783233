import { createElement, lazy } from "react";

import { WithAuthenticationRequired } from "../auth";

export default [
  {
    path: "login",
    element: createElement(lazy(() => import("../auth/login"))),
  },
  {
    path: "logout",
    element: createElement(lazy(() => import("../auth/logout"))),
  },
  // logged in routes
  {
    element: createElement(WithAuthenticationRequired),
    children: [
      {
        path: "*",
        element: createElement(
          lazy(() => import("../../components/admin/AdminPanel")),
        ),
      },
    ],
  },
]; // todo: TypeScript 4.9 `satisfies RouteObject[]`
