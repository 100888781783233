import { createElement } from "react";
import { createRoot } from "react-dom/client";

import { App, SuspenseRouter } from "./components";

const app = createElement(App, { router: SuspenseRouter });

const rootElement = document.createElement("div");
void document.body.append(rootElement);

// https://tailwindcss.com/docs/dark-mode#supporting-system-preference-and-manual-selection
if (window.matchMedia("(prefers-color-scheme: dark)").matches)
  rootElement.classList.add("dark");
void window
  .matchMedia("(prefers-color-scheme: dark)")
  .addEventListener("change", (event) => {
    if (event.matches) rootElement.classList.add("dark");
    else rootElement.classList.remove("dark");
  });

const root = createRoot(rootElement);
void root.render(app);
