import { PropsWithChildren, useState } from "react";
import { Outlet } from "react-router-dom";

import config from "../../utils/config";

export default function ConfigChecker({
  children = <Outlet />,
}: PropsWithChildren) {
  const [invalid, setInvalid] = useState(config.AUTH0_DOMAIN === "");

  const getLayerConfig = () => {
    void fetch("/backend/config/admin.json").then((response) => {
      if (response.ok) {
        void response.json().then((newConfig: { [s: string]: unknown }) => {
          Object.entries(newConfig).forEach(([key, value]) => {
            config[key] = value;
          });
          if (config.AUTH0_DOMAIN !== "") {
            setInvalid(false);
            return;
          }
          // then keep trying after a delay
          setTimeout(getLayerConfig, 5000);
        });
      }
    });
  };

  if (invalid) {
    setTimeout(getLayerConfig, 5000);
    return <div>Error getting config from the backend, retrying...</div>;
  }

  return <>{children}</>;
}
