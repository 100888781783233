declare global {
  interface Window {
    __layerConfig: {
      AUTH0_CLIENT_ID: string;
      AUTH0_DOMAIN: string;
      AUTH0_AUDIENCE: string;
      IS_PRODUCTION: boolean;
      SUBDOMAIN: string;
    };
  }
}
export interface LayerConfig {
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
  IS_PRODUCTION: boolean;
  SUBDOMAIN: string;
}

const defaultConfig: LayerConfig = {
  AUTH0_DOMAIN: "",
  AUTH0_CLIENT_ID: "",
  AUTH0_AUDIENCE: "",
  IS_PRODUCTION: false,
  SUBDOMAIN: "dev",
};

const layerConfig = window.__layerConfig || defaultConfig;

export const layerBaseUrl = `https://${layerConfig.SUBDOMAIN}.layer.ai`;
export const filesBaseUrl = `${layerBaseUrl}/media`;
export const bucketBaseUrl = `https://console.cloud.google.com/storage/browser/_details/layer-stack-gcp-${layerConfig.SUBDOMAIN}`;
export const auth0BaseUrl = `https://manage.auth0.com/dashboard/eu/layer-stack-${layerConfig.SUBDOMAIN}`;
export const baseStripeUrl = `https://dashboard.stripe.com${
  layerConfig.IS_PRODUCTION ? "" : "/test"
}`;
export default layerConfig;
